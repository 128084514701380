import * as Qs from "qs";
import moment from "moment";
import figlet from "figlet";
import standard from "figlet/importable-fonts/Standard.js";

// @ts-ignore
const CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
// @ts-ignore
const API_KEY = process.env.GOOGLE_API_KEY;

// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOC =
  "https://sheets.googleapis.com/$discovery/rest?version=v4";

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES =
  "https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/userinfo.email";

let USER_EMAIL = localStorage.getItem("ue");
let USER_NAME = localStorage.getItem("un");
let ACCESS_TOKEN = localStorage.getItem("at");
let ACCESS_TOKEN_EXPIRATION = localStorage.getItem("ate");
let ACCESS_TOKEN_EXPIRATION_DATE = ACCESS_TOKEN_EXPIRATION
  ? parseInt(ACCESS_TOKEN_EXPIRATION)
  : null;

window.onload = function () {
  var clickCount = 0;
  var lastClickTime = 0;
  var timeThreshold = 500; // Time threshold in milliseconds (adjust as needed)

  function toco() {
    var currentTime = new Date().getTime();

    if (currentTime - lastClickTime < timeThreshold) {
      clickCount++;
      if (clickCount === 6) {
        signOut();
      }
    } else {
      clickCount = 1;
    }

    lastClickTime = currentTime;
  }

  function validateIntegerInputValue() {
    // Obtener el valor actual del input
    const inputValue = this.value;

    const regex = /^[0-9]+$/;

    if (!regex.test(inputValue)) {
      // Si no coincide, eliminar los caracteres no válidos
      this.value = inputValue.replace(/[^0-9]/g, "");
    }
  }

  function selectInputValue() {
    this.setSelectionRange(0, this.value.length);
    this.select();
  }

  window.addEventListener("click", toco);
  window.addEventListener("touchstart", toco);

  const integerInput = document.getElementById(
    "modal-quantity"
  ) as HTMLInputElement;

  integerInput.addEventListener("input", validateIntegerInputValue);
  integerInput.addEventListener("click", selectInputValue);
};

gapi.load("client", async () => {
  await gapi.client.init({
    apiKey: API_KEY,
    discoveryDocs: [DISCOVERY_DOC],
  });

  const isTokenExpired = ACCESS_TOKEN_EXPIRATION_DATE
    ? Date.now() > ACCESS_TOKEN_EXPIRATION_DATE
    : true;

  if (!ACCESS_TOKEN || !USER_NAME || !USER_EMAIL || isTokenExpired) {
    fetchAccessToken();
  } else {
    gapi.client.setToken({ access_token: ACCESS_TOKEN });
    runApp();
  }
});

async function fetchAccessToken() {
  const tokenClient = google.accounts.oauth2.initTokenClient({
    client_id: CLIENT_ID as string,
    scope: SCOPES,
    prompt: "select_account",
    callback: async ({ access_token, expires_in, error }) => {
      if (error) {
        throw error;
      }

      ACCESS_TOKEN = access_token;
      ACCESS_TOKEN_EXPIRATION_DATE = Date.now() + parseInt(expires_in) * 1000;

      localStorage.setItem("at", ACCESS_TOKEN);
      localStorage.setItem("ate", ACCESS_TOKEN_EXPIRATION_DATE.toString());

      runApp();
    },
  });

  tokenClient.requestAccessToken();
}

async function runApp() {
  if (await fetchNombres()) {
    readProduct();
  } else {
    window.close();
  }
}

async function readProduct() {
  const { producto } = Qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  if (!producto) {
    alertar("Ta rara la URL, preguntale a alguien que pasa.");
    return;
  }

  const buttonAction = () => {
    const modalInput = document.getElementById(
      "modal-quantity"
    ) as HTMLInputElement;
    if (modalInput) {
      const cantidadString = modalInput.value;
      if (!cantidadString) {
        window.close();
        return;
      }

      const cantidad = parseInt(cantidadString);
      if (Number.isNaN(cantidad) || cantidad < 1) {
        showModal(
          `Vas a comprar: ${producto}. Cuantos queres? Pone un numero mayor a 0 cabeza`,
          "Comprar",
          buttonAction,
          true
        );
        return;
      } else {
        finishOrder(producto, cantidad);
      }
    }
  };

  showModal(
    `Vas a comprar: ${producto}. Cuantos queres?`,
    "Comprar",
    buttonAction,
    true
  );
}

async function finishOrder(producto, cantidad) {
  const date = moment().format("D/M/YYYY HH:MM:SS");
  const nuevaCompraRow = [[date, producto, USER_NAME, cantidad]];
  try {
    // @ts-ignore
    const comprasResponse = await gapi.client.sheets.spreadsheets.values.get({
      // @ts-ignore
      spreadsheetId: process.env.SPREADSHEET_DB_ID,
      range: "Compras!A:D",
    });
    const compras = comprasResponse?.result?.values;
    const rowNumber = compras ? compras.length + 1 : 1;
    // @ts-ignore
    await gapi.client.sheets.spreadsheets.values.update({
      // @ts-ignore
      spreadsheetId: process.env.SPREADSHEET_DB_ID,
      valueInputOption: "USER_ENTERED",
      range: `Compras!A${rowNumber}`,
      resource: {
        values: nuevaCompraRow,
      },
    });
    alertar("Quedo guardado, graciela");
  } catch (err) {
    console.error(err);
    alertar(
      "Se rompio todo, mira la consola o preguntale a alguien a ver que onda."
    );
  }
}

function promptProducto(producto, extra = "") {
  const cantidadString = window.prompt(
    `Vas a comprar: ${producto}. Cuantos queres? ${extra}`,
    "1"
  );

  if (!cantidadString) {
    return cantidadString;
  }

  const cantidad = parseInt(cantidadString);
  if (Number.isNaN(cantidad)) {
    return promptProducto(producto, "Pone un numero cabeza");
  }

  return cantidad;
}

async function fetchNombres() {
  try {
    const userInfoResponse = await fetch(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      }
    );
    const responseBody = await userInfoResponse.json();
    const userInfoEmail = responseBody.email;

    // @ts-ignore
    const namesSheetResponse = await gapi.client.sheets.spreadsheets.values.get(
      {
        // @ts-ignore
        spreadsheetId: process.env.SPREADSHEET_KZKIOSKO_ALMUERZOS,
        range: "0 - Nombres!A:C",
      }
    );
    const namesSheet = JSON.parse(namesSheetResponse.body).values;
    const emailRow = namesSheet.find((rows) => rows[2] === userInfoEmail);
    const name = emailRow ? emailRow[0] : null;

    USER_EMAIL = userInfoEmail;
    USER_NAME = name;

    if (!USER_NAME) {
      localStorage.clear();
      alertar("No ta tu mail en la sheet de kzAlmuerzos px!");
      return;
    }

    localStorage.setItem("ue", USER_EMAIL || "");
    localStorage.setItem("un", USER_NAME || "");
    return true;
  } catch (e) {
    console.error(e);
    alertar(
      "No tenes permisos de lectura en la KzAlmuerzos px, pedile a alguien. Y si no mira la consola."
    );
  }
}

/**
 *  Sign out the user upon button click.
 */
function signOut() {
  const token = gapi.client.getToken();
  if (token !== null) {
    google.accounts.oauth2.revoke(token.access_token, () => {
      gapi.client.setToken(null);
      localStorage.clear();
      alertar("Te fuiste px");
    });
  }
}

function alertar(message) {
  const { debug } = Qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  showModal(
    message,
    "Cerrar",
    () => {
      !debug && window.close();
    },
    false
  );
}

/**
 * MODAL
 */

function showModal(message, buttonMessage, buttonAction, isPrompt) {
  //set modal message
  const modalMessage = document.getElementById("modal-message");
  if (modalMessage) {
    modalMessage.innerText = message;
  }

  if (buttonMessage) {
    const modalButton = document.getElementById("modal-button");
    if (modalButton) {
      modalButton.innerText = buttonMessage;
      modalButton.onclick = buttonAction;
    }
  }

  const modalInput = document.getElementById(
    "modal-quantity"
  ) as HTMLInputElement;
  if (modalInput) {
    if (isPrompt) {
      modalInput.style.display = "block";
    } else {
      modalInput.style.display = "none";
    }
  }
  const modalContainer = document.getElementById("modal-container");

  if (modalContainer) {
    modalContainer.style.display = "block";
  }
}

// EASTER EGG

figlet.parseFont("Standard", standard);

figlet.text(
  "PUTO EL QUE LEE",
  {
    font: "Standard",
  },
  function (err, data) {
    console.log("%c" + data, "color: violet");
  }
);
